import React, {Component} from "react";

const clubName = [
    "Paurana Capital",
    "Coffee to Capital",
    "Capital Markets Association",
    "Rotman Commerce Finance Association"
] 
const initiative = [
    "Market Mondays",
    "Mentorship Program",
    "DCF Workshop",
    "Evercore Info Session"
]
const logoRoot = "./images/logos/"
const logo = [
    logoRoot + "UTEFAxPC1.png",
    logoRoot + "UTEFAxCTC1.png",
    logoRoot + "UTEFAxCMA1.png",
    logoRoot + "UTEFAxRCFA1.png"
]
const imgRoot = "./images/events/"
const image = [
    imgRoot + "MarketMondays.png",
    imgRoot + "DCF.png",
    imgRoot + "CTC.png",
    imgRoot + "Evercore.png"
]
const desc = [
    ["UTEFA and PC's equity research teams have joined forces to give our analysts the latest market insights! Every Monday, we will release a newsletter through email and on our social media channels.",
        "Join our mailing list to receive the weekly newsletter, and join our Discord server or come to weekly meetings to participate in discussions, ask questions, or propose a topic for our next newsletter."],
    ["UTEFA and CTC have come together to create a mentorship program that prepares you for your next coffee chat, networking event, or interview. 3 firm tours are also scheduled for the winter semester.",
        "You'll be matched to an upper year, and it's up to you to proactively stay in contact with them. When they think you're ready, we'll connect you with an alumni as the next step to growing your network. Follow our socials to know when applications open!"],
    ["UTEFA and CMA are partnering together for our DCF workshops, which will be vital aspects to both stock pitches and M&A reports.",
        "The workshop consists of a step-by-step guide followed by a live demo creating a DCF from scratch. By the end of the workshop you will have a detailed understanding of DCF valuation and have the tools to create your own DCF in the future."],
    ["UTEFA and RCFA are hosting a joint info session with Evercore Parters on December 2nd, a boutique investment bank based in New York. Their team of Toronto analysts came to introduce the firm and network with students.",
        "We encourage 2nd and 3rd year + PEY students to apply for their 2026 summer analyst position for their Toronto office. Roles are posted in January, reach out if you'd like support on resumes or interviews!"
    ]
]

export class Partners extends Component{
    render(){
        return(
            <div className = "partners">
                <hr className = "line"></hr>
                {clubName.map((club, i) =>
                    <div className = "wow fadeInUp collab">
                        <div className = "logoImg">
                            <img src = {logo[i]} alt = "logo"></img>
                        </div>
                        <div className = "collabContent">
                            <img src = {image[i]} className = "partnerImg" alt = "collab"></img>
                            <div className = "partnerDesc">
                                <div className = "textHeading">{initiative[i]}</div>
                                <div className = "textSubtitle">UTEFA X {club}</div> 
                                <div className = "normText">{desc[i][0]}</div>
                                <div className = "normText">{desc[i][1]}</div>
                            </div>
                        </div>
                    </div>        
                )}
                <hr className = "line"></hr>
            </div>
        )
    }
}