import React from 'react';
import Collapsible from 'react-collapsible';
// run "npm install react-collapsible --save" 

const fallMonths = ["SEP", "OCT", "NOV", ]
const winterMonths = ["JAN", "FEB", "MAR"]

const fallDates = [
    ["09/12/24", "09/19/24", "09/26/24"], 
    ["10/03/24", "10/10/24", "10/17/24", "10/24/24"],
    ["11/06/24", "11/21/24", "11/28/24"]
]    
const winterDates = [
    ["01/16/25", "01/23/25", "01/30/25"],
    ["02/08/25", "02/13/25", "02/27/25"],
    ["03/06/25", "03/13/25", "03/20/25", "03/27/25"]
]
const text = ["textBlue", "textPink", "textYellow"]

const fallEvent = [
    ["Intro to UTEFA", "Banking Alumni Panel", "Resume & Networking Workshop"],
    ["Leaders Alumni Panel", "Stock Pitch Intro", "DCF Workshop", "Stock Pitch Q&A"],
    ["Stock Pitch Presentation", "ETFs and Electronic Trading", "Founders Spotlight"],
]
const winterEvent = [
    ["Stock Pitch Info Session", "DCF Workshop II", "Intro to Portfolio Management"],
    ["Stock Pitch SUPERDAY", "Derivatives Workshop", "Inclusion in Finance"],
    ["Quant Traders Alumni Panel", "Commodities Workshop", "Recruitment Info & Tips", "UTEFA Exec Team Elections"]
]

const fallDesc = [
    ["We're kicking off the new school year with an introduction to UTEFA. Meet the team, learn about our events, and connect with fellow analysts! ",
    "We're bringing in UTEFA alumni in sell-side roles including investment banking, sales & trading, and corporate banking back to answer burning questions about the finance industry. They'll speak on their experiences and give advice to aspiring analysts.",
    "Networking and resume building are the most important parts of the recruitment process, yet the details are often overlooked. Our exec team will go over how to properly create resumes, build strong networks and make the most out of coffee chats. If you want individual advice on your resume, reach out to our team."],
    ["Handfuls of engineering alum have gone out to become esteemed leaders. This year, we are proud to invite Tom Woods, former CFO and CRO of CIBC, back to UofT to share his experience graduating Industrial Engineering to navigating and leading this ever-changing industry.",
    "Our fall stock pitch presentations help analysts with little to no stock pitch experience familiarize themselves with this crucial skill. We'll be teaching from the very beginning and providing mentorship throughout the way so this is your chance to give it a try before your first interview!",
    "DCF's are crucial in any equities market and is guaranteed to appear in your next banking interview. We'll give step by step instructions on how to make a DCF and have resources posted. This session will be hosted in partnership with UofT's Capital Markets Association.",
    "This is your chance to ask for feedback and practice in front of an audience. Don't hesitate to reach out if you have any questions!"],
    ["After weeks of preparation, teams will present their pitches to the exec team and fellow analysts. Everyone is welcome to come and watch! We'll be providing feedback at the end of presentations to help you improve for your next competition.",
    "Eric Bryce is an Associate Director at the National Bank of Canada in Electronic Trading. He will be giving a workshop on ETFs and Equity Trading Micro-Structure, as well as giving a rundown of the day-to-day as a quant trader.",
    "We've invited an esteemed UofT Engineering alumnus, Som Seif, Co-Founder of Wealthsimple and Founder and CEO of Purpose Financial, to speak about his inspirational story transitioning from Engineering into Investment Banking, and then into founding his own companies.",    
    ]
]
const winterDesc = [
    ["Winter semester is for our Stock Pitch Superday! Learn about logistics, rules, and how to get involved. Join our socials to be updated whenever we release new information!", 
    "A second DCF workshop will be hosted for those who missed the first one or would like to learn about DCFs in more detail. We'll also leave time for Q&A about Superday",
    "Emil Kerimov, a Porfolio Manager at CPP in Total Fund Management and Engsci Alumnus, will be giving a workshop on Quantitiative Porfolio Management Strategies as well as building a career in buy-side finance as an Engineering alumni."],
    ["This Saturday is scheduled for a full day of presentations, networking, and refreshments! All teams will be judged by alumni from across the industry. We're super excited to host this event in person this year so stay tuned for more info.",
    "Derivatives are a special and nuanced asset class. We'll give a brief introduction to futures, forwards, and options, and how these contracts are leveraged in different market positions.",
    "Women make less than a third of C-suite executives in the finance world, and exclusively lead less than 2% of investment teams. Learn about why diversity is important in investments, the abundance of diversity programs for gender and racial minorities and the endless opportunities to build a career in finance.",],
    ["Quantitative investment strategies have gained significant popularity in recent years, highlighting the vast potential for tech and math to contribute to the investing space. Hear from alumni in quant roles from a variety of backgrounds and their experiences in this field.",
    "Learn about commodoties trading and how they can used to diversify a portfolio.",
    "As our final workshop, we'll be providing a rundown of the recruitment process for various sectors of the market and give tips on how to succeed at each step of the recruitment process. We'll be bringing in people on PEY, graduating, or finishing up their first/second year.",
    "To wrap up the school year, we'll be hosting UTEFA elections for the next year's executive team. If UTEFA sounds like a club you'd like to contribute more to, make sure to look out for when applications open and speak to our execs if you have any questions!"]
]

const Collapse = () => {
    return (
        <div className="schedule">
            <hr className = "line"></hr>
            <div className = "textBold">All Thursday meetings run 6-7 PM unless stated otherwise</div>
            <div className = "textCaption">Click on each event to learn more. 
                Location and other details will be posted on our socials.
            </div>
            <div className = "textTitle">Fall 2024</div>
            {fallMonths.map((months, i) =>
                <div className="calendar">
                    <div className= {"month " + text[i]}>
                        {months}
                    </div>
                    <div className="calendarEvent">
                        {fallDates[i].map((dates, j) =>
                            <Collapsible 
                                trigger={
                                    <div className="wow fadeInUp monthEvent">
                                        <div className="date textBold">{dates}</div>
                                        <div className="event textBold">{fallEvent[i][j]}</div>
                                        <div className="descDisplay textBold">+</div>
                                    </div>
                                } 
                                className="monthEventDesc" 
                                triggerWhenOpen={
                                    <div className="monthEvent">
                                        <div className="date textBold">{dates}</div>
                                        <div className="event textBold">{fallEvent[i][j]}</div>
                                        <div className="textBold">-</div>
                                    </div>
                                }>
                                <div className="eventDesc normText">
                                    {fallDesc[i][j]}
                                </div>
                            </Collapsible>
                        )}
                    </div>
                </div>
            )}
            <div className = "textTitle">Winter 2025</div>
            {winterMonths.map((months, i) =>
                <div className="calendar">
                    <div className={"month " + text[i]}>
                        {months}
                    </div>
                    <div className="calendarEvent">
                        {winterDates[i].map((dates, j) =>
                            <Collapsible 
                                trigger={
                                    <div className="wow fadeInUp monthEvent">
                                        <div className="date textBold">{dates}</div>
                                        <div className="event textBold">{winterEvent[i][j]}</div>
                                        <div className="descDisplay textBold">+</div>
                                    </div>
                                } 
                                className="monthEventDesc" 
                                triggerWhenOpen={
                                    <div className="monthEvent">
                                        <div className="date textBold">{dates}</div>
                                        <div className="event textBold">{winterEvent[i][j]}</div>
                                        <div className="descDisplay textBold">-</div>
                                    </div>
                                }>
                                <div className="eventDesc normText">
                                    {winterDesc[i][j]}
                                </div>
                            </Collapsible>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
  
export default Collapse;